<script>
import { ArrowUpIcon, SettingsIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import { SET_TRUST_EMPTY, CLEAR_TRUST } from "@/services/store/trust.module";
import ApiService from "@/services/api.service";
import { RESET } from "@/services/store/amanah-pusaka.module";

export default {
  data() {
    return { insert_code: null };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    SettingsIcon,
  },
  computed: {
    error() {
      return this.$route.query.error;
    },
    ...mapGetters(["currentUser", "userRole"]),
  },
  mounted() {
    // this.$store.dispatch(CLEAR_TRUST);
  },
  methods: {
    async pushRedirect(type) {
      // if (this.userRole == "Client") {
      if (type) {
        let hibahId = await this.getHibah(type);
        // alert(hibahId);

        if (type == 14) {
          this.$store.dispatch(RESET);
          this.$router.push({
            name: "trust-inheritance.account",
          });
          return;
        }
        if (hibahId) {
          if (type == 12) {
            this.$router.push({
              name: "trust-onecent.part-d",
              query: { trust_id: hibahId },
            });
          } else {
            this.$router.push({
              name: "trust.part-a",
              query: { trust_id: hibahId },
            });
          }
        } else {
          if (type == 12) {
            this.$router.push({
              name: "trust-onecent.redeem",
            });
          } else {
            this.$router.push({
              name: "trust.part-a",
              query: { type: type },
            });
          }
        }
      }
      // } else {
      //   this.$router.push({ name: "trust.part-a", query: { type: type } });
      // }
    },
    pushRedirectOneCent(hibah_type_idID) {
      this.$refs["onecentcode"].show();
    },
    async getHibah(type) {
      return await ApiService.get(
        `hibah?hibah_type_id=${type}&user_id=${this.currentUser.id}&return_first=true`
      )
        .then((response) => {
          return response.data.hibahs ? response.data.hibahs.id : null;
        })
        .catch(() => {
          return null;
        });
    },
    // async insertCode() {
    //   console.log("insertCode", this.insert_code);
    //   ApiService.get(`/one-cent/hibah-coupon/${this.insert_code}/redeem`)
    //     .then((response) => {
    //       console.log("response token", response.data);
    //       this.$router.push({
    //         name: "trust-onecent.part-d",
    //         query: { trust_id: response.data.hibah.id },
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white">{{ $t("trust-product") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2 mt-4 pt-2">
            <div class="alert alert-warning" v-if="error == 1">
              {{ $t("choose-product") }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(2)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("corporate-trust-declaration") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("corporate-trust-declaration-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(4)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("cash-trust-declaration") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("cash-trust-declaration-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(6)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("property-trust-declaration") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("property-trust-declaration-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(8)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("cash-trust") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">{{ $t("cash-trust-info") }}</p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(10)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("property-trust") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("property-trust-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(11)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("business-trust") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("business-trust-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 offset-md-3 mt-4 pt-2">
            <div @click="pushRedirect(14)">
              <div class="card rounded shadow bg-dark border-0">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="text-white">{{ $t("estate-trust") }}</h4>
                      <div class="mt-4">
                        <p class="h6 text-muted mb-0">
                          {{ $t("estate-trust-info") }}
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm">
                      <img src="/images/icon/next.png" height="30" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <b-modal
        ref="onecentcode"
        id="onecentcode"
        class="modal fade"
        title="Please Select"
        ok-title="OK"
        @ok="insertCode"
      >
        <div class="form-group">
          <label> Insert Code </label>
          <input type="text" class="form-control" v-model="insert_code" />
        </div>
      </b-modal> -->
    </section>

    <Footer />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
