<script>
import { ArrowUpIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      organization: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
      promoCodes: [],
      form: {
        startDate: "",
        endDate: "",
        status: "",
      },
      // selectedOption: null,
      // options: [
      //   { value: 1, text: 'Generated By'},
      //   { value: 2, text: 'Redeemed At'},
      //   { value: 3, text: 'Redeemed By'},
      // ]
    };
  },
  components: {
  //  mounted() {
  //   if (this.userAccess) {
  //     this.organization_id = this.userAccess.organization_id;
  //     console.log(this.organization_id);
  //   }
  //  }
  // optionToString() {
  //   return JSON.stringify(this.option);
  // }
  },
  watch: {
  },
  methods: {
    openModalExport() {
      this.$refs["modalExportPromoCode"].show();
    },
    async exportToExcel() {
      // console.log(this.form); return;

      var route = await this.signRoute("organizations.promo-codes.export", {
        organization: this.userAccess.organization.id,
        start_date: this.form.startDate,
        end_date: this.form.endDate,
        is_redeemed: this.form.status, // Yes, No, null
      });
      window.open(route, "_blank");
    },
  },
  filters: {

  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userRole", "userAccess"]),
    orgCode() {
      return this.userAccess?.organization?.code;
    },
  },
};
</script>

<template>
      <b-modal ref="modalExportPromoCode" class="modal fade" v-bind:title="$t('export-promo-code')" v-bind:ok-title="$t('export')"  v-bind:cancel-title="$t('cancel')" @ok="exportToExcel">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="tt-cap">
                {{ $t("start-date") }}
                <span class="text-danger">*</span>
              </label>
              <input type="date" class="form-control" v-model="form.startDate" />
            </div>
            <div class="col-md-12 form-group">
              <label class="tt-cap">
                {{ $t("last-date") }}
                <span class="text-danger">*</span>
              </label>
              <input type="date" class="form-control" v-model="form.endDate" />
            </div>
            <div class="col-md-12 form-group">
              <label class="tt-cap" for="selectedOption">
                {{ $t("status") }}
                <span class="text-danger">*</span>
              </label>
              <select class="form-control" v-model="form.status">
                <option disabled>{{ $t("status") }}</option>
                <option value="1">{{ $t("only-redeemed") }}</option>
                <option value="2">{{ $t("only-not-redeemed") }}</option>
                <option value="3">{{ $t("all-redeemed-not-redeemed") }}</option>
              </select>
            </div>
          </div>
        </div>
      </b-modal>
</template>
