<script>
import { ArrowUpIcon } from "vue-feather-icons";
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      organizations: [],
      form: {
        start_date: "",
        end_date: "",
      },
    };
  },
  mounted() {
    this.getOrganizations();
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
  },
  computed: {
    ...mapGetters(["userRole"]),
    error() {
      return this.$route.query.error;
    },
  },
  validations: {
    form: {
      start_date: {
        required,
      },
      end_date: {
        required,
      },
    },
  },
  methods: {
    getOrganizations() {
      const params = {
        limit: 100,
      };
      const queryString = new URLSearchParams(params).toString();
      ApiService.get(`/organization?` + queryString)
        .then((response) => {
          this.organizations = response.data.organizations.data;
        })
        .catch((error) => {});
    },
    async generateReport() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v);
        this.$swal.fire({
          icon: "warning",
          html: "Sila isi start & end date",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Tutup",
        });
        return;
      }
      const signedRoute = await this.signRoute("reports.wills", this.form);
      console.log(signedRoute);
      window.open(signedRoute, "_blank");
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white" style="text-transform: capitalize">
                {{ $t("reporting.will") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container card public-profile border-0 rounded shadow overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <label for="date" class="col-md-4 col-form-label">Date Start</label>
                <div class="col-md-8">
                  <input type="date" class="form-control" v-model.trim="$v.form.start_date.$model" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <label for="date" class="col-md-4 col-form-label">Date End</label>
                <div class="col-md-8">
                  <input type="date" class="form-control" v-model.trim="$v.form.end_date.$model" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <label for="date" class="col-md-4 col-form-label">Status</label>
                <div class="col-md-8">
                  <select name="" id="" class="form-control" v-model="form.status">
                    <option value="0">Belum Selesai</option>
                    <option value="1">Tunggu Pengesahan</option>
                    <option value="2">Lulus</option>
                    <option value="3">Ditolak</option>
                    <option value="">Semua</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="['Staff'].includes(userRole)">
              <div class="row">
                <label for="date" class="col-md-4 col-form-label">Organisasi</label>
                <div class="col-md-8">
                  <select name="" id="" class="form-control" v-model="form.organization_id">
                    <option value=""></option>
                    <option v-for="organization in organizations" :value="organization.id" :key="organization.id">{{ organization.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <button class="btn btn-primary" v-on:click="generateReport">Generate Excel</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
