import Vue from 'vue';
import ApiService from "@/services/api.service";

Vue.mixin({
    methods: {
        async signRoute(route_name, params) {
            var data = { ...params, route_name };
            return ApiService.post(`/route/sign`, data)
                .then((response) => {
                    return response.data.url;
                })
                .catch((error) => {
                    return '';
                });
        }
    }
})