<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";

import { IS_EKYC_COMPLETE, FAILED_COUNT, MYKAD_FRONT, SELFIE } from "@/services/store/wasiat.module";
import "image-capture/src/imagecapture.js";
import ManualeKYC from "@/views/wasiat/asas/part-a/manual-ekyc";
import eKYCComplete from "@/views/wasiat/asas/part-a/ekyc-complete";
import eKYC from "@/views/wasiat/asas/part-a/ekyc";

export default {
  data() {
    return {
      canvas: null,
      alertMessage: null,
      mobileCamera: "Kamera Depan",
      videoDevices: null,
      timeOut: null,
      mediaDevice: {
        video: true,
      },
      countDown: 15000,
      video: {},
      ocrMethod: 1,
      noCamera: false,
      ocrResponse: null,
      facingMode: "environment",
      mediaStream: null,
      selfie: null,
      mykad: null,
    };
  },
  components: {
    ekyc: eKYC,
    "manual-ekyc": ManualeKYC,
    "ekyc-complete": eKYCComplete,
  },
  watch: {
    isEkycCompleteWasiat: {
      handler: function (val) {
        // console.log(val);
        this.finishload = true;
        // this.form.ic_number = this.trust.user.ic_number;
      },
    },
    
  },
  methods: {
    handleClick(wasiatId) {
      // Your custom logic here
      console.log("Link clicked!");      
      // If you want to navigate to the href URL after your logic
      window.location.href = `/wasiat/part-d?wasiat_id=${wasiatId}`;
    },
    async onSubmit() {
      this.$router.push({
        name: "wasiat.part_d",
        query: {
          wasiat_id: this.wasiat.id,
          pengesahan: this.pengesahan,
        },
      });
    },
    async streamVideo() {
      navigator.mediaDevices
        .getUserMedia(this.mediaDevice)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          try {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
          } catch (error) {}
        })
        .catch((error) => {
          console.log("Stream error", error);
          if (String(error) == "NotAllowedError: Permission denied") {
            this.noCamera = true;
            this.$swal.fire({
              icon: "error",
              html: this.$t("ekyc.allow-camera"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("ok"),
            });
          }
        });
    },
    async getCameraSelection() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");

      this.videoDevices = videoDevices ?? [];
      // console.log("total device -- ", this.videoDevices);
      this.mediaDevice = {
        video: {
          deviceId: videoDevices[0].deviceId,
        },
      };
      this.streamVideo();
    },
    // async getConnectedDevices(type) {
    //   const devices = await navigator.mediaDevices.enumerateDevices();
    //   return devices.filter((device) => device.kind === type);
    // },
    selectCamera(event) {
      this.mediaDevice = {
        video: {
          deviceId: event.target.value,
        },
      };
      try {
        this.streamVideo();
      } catch (error) {
        console.log(error);
      }
    },
    async mobileChangeCamera() {
      try {
        if (this.mobileCamera == "Kamera Depan") {
          this.mediaDevice = {
            video: { facingMode: { exact: "environment" } },
          };
          this.mobileCamera = "Kamera Belakang";
        } else {
          this.mobileCamera = "Kamera Depan";
          this.mediaDevice = {
            video: { facingMode: "user" },
          };
        }
        await this.streamVideo();
      } catch (error) {
        console.log("BTN MOBILE SELECT CAMERA", error);
      }
    },
    capture(type) {
      const vm = this;
      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(mediaStreamTrack);
      this.alertMessage = `Gambar ${type} telah berjaya diambil.`;
      // vm.$notify({
      //   group: "foo",
      //   title: `Gambar ${type} telah berjaya diambil.`,
      //   duration: 5000,
      //   type: "success",
      // });
      //  Vue.swal({
      //   icon: "error",
      //   html: errorMessage,
      //   showCloseButton: true,
      //   showCancelButton: false,
      //   focusConfirm: false,
      //   confirmButtonText: this.$t("close"),
      // });
      return imageCapture.takePhoto().then((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          if (type == "selfie") {
            vm.selfie = reader.result.split(",")[1];
            vm.$store.dispatch(SELFIE, vm.selfie);
            // console.log("selfie", vm.selfie);
          } else if (type == "mykad") {
            vm.mykad = reader.result.split(",")[1];
            // console.log("mykad", vm.mykad);
            vm.$store.dispatch(MYKAD_FRONT, vm.mykad);
          }
        };
      });
    },
    changeCamera() {
      if (this.facingMode == "environment") {
        this.facingMode = "user";
      } else {
        this.facingMode = "environment";
      }
    },
    faceVerification() {
      const vm = this;
      this.alertMessage = null;
      ApiService.post("/face-verification", {
        selfie_image: this.selfie,
        mykad_image: this.mykad,
        user_id: this.wasiat.user_id,
        ekycable_id: this.wasiat.id,
        ekycable_type: "Wasiat",
      })
        .then((response) => {
          console.log("SCORE: ", response.data.face_verification.verification_score);
          if (response.data.face_verification.verification_score >= this.verificationRate) {
            vm.$store.dispatch(IS_EKYC_COMPLETE, true);
            this.$swal
              .fire({
                icon: "success",
                html: "e-KYC berjaya!",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: "Seterusnya",
                willClose: () => {
                  vm.$router.push({
                    name: "wasiat.part_d",
                    query: { wasiat_id: this.wasiat.id },
                  });
                },
              })
              .then((response) => {
                // vm.$store.dispatch(IS_EKYC_COMPLETE, true);
              });
            var data_ocr = respone.data.ocr_data;
          } else {
            this.$store.dispatch(FAILED_COUNT);
            var score = parseInt(response.data.face_verification.verification_score);
            this.$swal.fire({
              icon: "warning",
              html: this.$t("ekyc-score-failed") + score + "%",
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("try-again"),
              willClose: () => {
                this.selfie = null;
                this.mykad = null;
              },
            });
          }
        })
        .catch((error) => {
          //   this.failedCount++;
          this.$store.dispatch(FAILED_COUNT);
          this.selfie = null;
          this.mykad = null;
        });
    },
   
    manual() {
      clearTimeout(this.timeOut);

      this.$store.dispatch(FAILED_COUNT);
      // this.$router.push({
      //   name: "wasiat.part_c",
      //   query: { wasiat_id: this.wasiat.id },
      // });
    },
    countDownTimer() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.$swal
          .fire({
            icon: "warning",
            html: this.$t("ekyc.perform-manual"),
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: this.$t("manual-ekyc"),
            cancelButtonText: this.$t("close"),
            showCloseButton: true,
          })
          .then((result) => {
            if (result.value) {
              // this.$swal(
              //   "Deleted",
              //   "You successfully deleted this file",
              //   "success"
              // );
              this.manual();
              console.log("manual click");
            } else {
              this.countDownTimer();
            }
          });
        // this.countDownTimer();
      }, 15000);
    },
  },
  unmounted() {
    // console.log("unmounted");
    clearTimeout(this.timeOut);
    const tracks = this.mediaStream.getTracks();
    tracks.map((track) => track.stop());
  },
  destroyed() {
    // console.log("destroyed");

    clearTimeout(this.timeOut);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    // console.log("mouted ekyc");
    clearTimeout(this.timeOut);

    this.getCameraSelection();
    this.countDownTimer();
    
  },

  computed: {
    wasiatId() {
      return this.$route.query.wasiat_id;
    },
    ...mapGetters(["isAuthenticated","isEkycCompleteWasiat","currentUser", "userRole", "failedCountWasiat", "mykadFrontWasiat", "wasiat"]),
    verificationRate() {
      return process.env.VUE_APP_DOT_VERIFICATION_RATE;
    },
    showlistCamera() {
      return this.$route.query.listcamera;
    },
    pengesahan() {
      return this.$route.query.pengesahan;
    },
    wasiat_details() {
      return this.wasiat;
    },
  },
  destroyed() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
};
</script>

<template>
  <div class="col-lg-12 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
    <div class="row" v-if="wasiat">
      <div class="col-lg-12 col-12 mb-4 pb-2" v-if="!isEkycCompleteWasiat && failedCountWasiat <= 0">
        <ekyc> </ekyc>
      </div>
      <div class="col-lg-12 col-12 mb-4 pb-2" v-if="!isEkycCompleteWasiat && failedCountWasiat > 0">
        <manual-ekyc> </manual-ekyc>
      </div>
      <div class="col-lg-12 col-12 mb-4 pb-2" v-if="isEkycCompleteWasiat">
        <ekyc-complete> </ekyc-complete>
      </div>
    </div>
  </div>
</template>
