<script>
import ApiService from "@/services/api.service";
import moment from "moment";
export default {
  data() {
    return {
      transferDate: null,
      date: null,
      endDate: null,
      transactions: [],
      responseDate: {
        start: null,
        end: null,
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
    };
  },
  methods: {
    openModal(items) {
      this.transactions = items;
      this.$refs["show-modal"].toggle();
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMMM YYYY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 25rem;
  overflow: auto;
  display: inline-block;
}
</style>

<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="show-modal"
      size="lg"
      title="Maklumat Pelanggan"
      cancel-title="Batal"
      centered
    >
      <div class="table-responsive bg-white shadow rounded mt-3 table-wrapper">
        <table
          id="table"
          class="table mb-0 table-center table-bordered"
          style="font-size: 11px"
        >
          <thead class="bg-light">
            <tr> 
              <th rowspan="2">{{ $t("index") }}</th> 
              <th rowspan="2">{{ $t("ref-no") }}</th>
              <th rowspan="2" class="text-center">{{ $t("client") }}</th>
              <th rowspan="2" class="text-center">{{ $t("org.nav3") }} (RM)</th>
              <th rowspan="2">{{ $t("system-date") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="transactions && transactions.length == 0">
              <td class="text-center" colspan="6">
                <h6 class="mt-5 mb-5">{{ $t("no-data") }}</h6>
              </td>
            </tr>
            <tr v-for="(transaction, index) in transactions" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ transaction.ref_no }}</td>
              <td>
                {{ transaction.client_name }}
              </td>
              <td class="text-right">{{ transaction.price }}</td>
              <td>
                {{ transaction.created_at | toHumanDateTime }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>