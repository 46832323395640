<script>
import ApiService from "@/services/api.service";
import { TheMask } from "vue-the-mask";
import { tippy } from "vue-tippy";

export default {
  name: "HibahPartA",
  data() {
    return {
      form: {},
      submit: false,
    };
  },
  mounted() {},
  methods: {
    submitForm() {},
  },
  computed: {
    hibahId() {
      return this.$route.query.hibah_id;
    },
    amendmentId() {
      return this.$route.query.amendment_id;
    },
    trustId() {
      return this.$route.query.trust_id;
    },
    properyId() {
      return this.$route.query.property_id;
    },
    routeName() {
      return this.$route.name;
    },
    message() {
      return this.$route.query.message;
    },
  },
  components: { TheMask },
};
</script>

<template>
  <div class="col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
    <div class="row">
      <div class="col-md-12" v-if="message == 'saved'">
        <div class="alert alert-success">{{ $t("trust.movprop-success") }}</div>
      </div>
      <div class="col-md-12">
        <router-link
          class="d-flex align-items-center"
          :to="{
            name: 'trust-inheritance.property.moveable',
            query: { hibah_id: this.hibahId, amendment_id: amendmentId },
          }"
        >
          <img src="/images/icon/left-arrow.png" height="20" alt="" />
          <p
            class="m-0"
            style="padding-left: 10px; padding-top: 2px; display: inline-block"
          >
            {{ $t("back-to") }} {{ $t("property-list") }}
          </p>
        </router-link>
      </div>
      <div class="col-lg-12 col-12 mb-4 pb-2 mt-3">
        <div
          class="card bg-light job-box rounded shadow border-0 overflow-hidden"
        >
          <div class="card-bodyz">
            <h5 class="text-center mb-0 mt-4">
              {{ $t("property-information") }}
              <img
                content="Senarai penerima yang akan diagihkan dalam aset"
                v-tippy="{ content: $t('list-of-beneficiaries-asset') }"
                height="20"
                class="mb-1"
                src="/images/icon/info-button.png"
              />
            </h5>

            <ul class="nav nav-tabs mt-4">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.moveable-property.details'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.moveable-property.details',
                    query: {
                      hibah_id: hibahId,
                      property_id: properyId,
                      amendment_id: amendmentId,
                    },
                  }"
                >
                  {{ $t("property-information") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.moveable-property.receiver'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.moveable-property.receiver',
                    query: {
                      hibah_id: hibahId,
                      property_id: properyId,
                      amendment_id: amendmentId,
                    },
                  }"
                >
                  {{ $t("receiver-of-property") }}
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.moveable-property.attachment'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.moveable-property.attachment',
                    query: {
                      hibah_id: hibahId,
                      property_id: properyId,
                      amendment_id: amendmentId,
                    },
                  }"
                >
                  {{ $t("document") }}/{{ $t("appendix") }}
                </router-link>
              </li> -->
            </ul>
            <router-view> </router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
