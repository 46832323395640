<script>
import ApiService from "@/services/api.service";
import { TheMask } from "vue-the-mask";
import { tippy } from "vue-tippy";
import { required } from "vuelidate/lib/validators";
export default {
  name: "HibahPartA",
  data() {
    return {
      property: {},
      properties: [],
      totalValue: 0,
      productTransactions: [],
      form: {},
      submit: false,
    };
  },
  validations: {},
  mounted() {
    this.getProperties();
    this.getProductTransactions();
  },
  methods: {
    getProperties() {
      ApiService.get(`hibah/${this.hibahId}/properties/summary`).then(
        (response) => {
          this.properties = response.data.properties ?? [];
          this.totalValue = response.data.total_value ?? 0;
        }
      );
    },
    getProductTransactions() {
      ApiService.get(`hibah/${this.hibahId}/product-transaction`).then(
        (response) => {
          this.productTransactions =
            response.data.product_transactions.data ?? [];
        }
      );
    },
    openModalTransaction() {
      this.$v.$reset();
      this.form.type = null;
      this.form.amount = null;
      this.form.balance_before = null;
      this.form.balance_after = null;
      this.form.description = null;

      this.$refs["modalAddTransaction"].show();
    },
    hideModal() {
      this.$refs["modalAddTransaction"].hide();
    },
    deleteProductTransaction(transactionId, index) {
      ApiService.post(`product-transaction/${transactionId}`, {
        _method: "DELETE",
      }).then((response) => {
        this.getProductTransactions();
      });
    },
    async submitForm() {
      ApiService.post(
        `hibah/${this.hibahId}/product-transaction`,
        this.form
      ).then((response) => {
        this.getProductTransactions();
      });
    },
  },
  computed: {
    hibahId() {
      return this.$route.query.hibah_id;
    },
  },
  components: {},
};
</script>

<template>
  <div>
    <div class="mt-2">
      <!-- <div class="text-right mt-4">
            <b-button
            @click="openModalTransaction"
            class="btn btn-primary btn-sm"
            >
            + Transaksi
            </b-button>
        </div> -->
      <div class="table-responsive mt-3 mb-4">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("property-type2") }}</th>
              <th>{{ $t("details") }}</th>
              <th class="text-right">{{ $t("total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(property, index) in properties" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ property.category }}</td>
              <td>
                <router-link
                  v-if="property.type == 'immoveable'"
                  :to="{
                    name: 'trust-inheritance.immoveable-property.details',
                    query: {
                      hibah_id: hibahId,
                      property_id: property.id,
                    },
                  }"
                >
                  <span>{{ property.property_no }}</span>
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: 'trust-inheritance.moveable-property.details',
                    query: {
                      hibah_id: hibahId,
                      property_id: property.id,
                    },
                  }"
                >
                  <span v-if="property.category == 'Kenderaan'">{{
                    property.transport_model
                  }}</span>
                  <span v-else>{{ property.institution_name }}</span>
                </router-link>
              </td>
              <td class="text-right">RM {{ property.property_value }}</td>
            </tr>
            <tr v-if="properties.length > 0">
              <td colspan="3" class="text-right"><strong>{{ $t("total") }}</strong></td>
              <td class="text-right">
                <strong>RM {{ totalValue }}</strong>
              </td>
            </tr>
            <tr v-if="properties.length == 0">
              <td colspan="7" class="text-center">
                <h6 class="mt-3 mb-3">{{ $t("no-prop") }}</h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr class="primary" />
    <div class="mt-2">
      <div class="text-right mt-4">
        <b-button @click="openModalTransaction" class="btn btn-primary btn-sm">
          + {{ $t("transaction") }}
        </b-button>
      </div>
      <div class="table-responsive mt-3 mb-4">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("total") }}</th>
              <th>{{ $t("current-total") }}</th>
              <th>{{ $t("balance-aft") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, index) in productTransactions"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ transaction.type == 1 ? "Debit" : "Credit" }}</td>
              <td>RM {{ transaction.amount }}</td>
              <td>RM {{ transaction.balance_before }}</td>
              <td>RM {{ transaction.balance_after }}</td>
              <td>{{ transaction.description }}</td>
              <td>
                <b-button
                  @click="deleteProductTransaction(transaction.id, index)"
                  class="btn-sm btn-danger"
                >
                  {{ $t("remove") }}
                </b-button>
              </td>
            </tr>
            <tr v-if="productTransactions.length == 0">
              <td colspan="7" class="text-center">
                <h6 class="mt-3 mb-3">{{ $t("no-transactions") }}</h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      :no-enforce-focus="true"
      title="Tambah Transaksi"
      ref="modalAddTransaction"
      class="modal fade"
      v-bind:ok-title="$t('add')"
      v-bind:cancel-title="$t('cancel')"
      @ok="submitForm"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="type">{{ $t("transaction-type") }}</label>
              <span class="text-danger">*</span>
              <select name="" id="" class="form-control" v-model="form.type">
                <option value="">- {{ $t("pls-select-transaction-type") }} -</option>
                <option value="1">Debit</option>
                <option value="2">{{ $t("credit") }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="total">{{ $t("total") }}</label>
              <span class="text-danger">*</span>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RM</span>
                </div>
                <input type="text" class="form-control" v-model="form.amount" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="balance_before">{{ $t("balance-aft") }}</label>
              <span class="text-danger">*</span>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RM</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.balance_before"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="balance_after">{{ $t("balance-aft") }}</label>
              <span class="text-danger">*</span>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RM</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.balance_after"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="description">{{ $t("description") }}</label>
              <span class="text-danger">*</span>
              <textarea
                v-uppercase
                class="form-control"
                v-model="form.description"
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
