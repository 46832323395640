<script>
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import ExportModal from "@/views/promo-codes/modal.vue"
import { ArrowUpIcon } from "vue-feather-icons";
import ApiService from "@/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      organization: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
      promoCodes: [],
      form: {
        remarks: "",
        count: 10,
      },
    };
  },
  // name: 'modal',

  components: {
    Navbar,
    ArrowUpIcon,
    Footer, 
    ExportModal, 
  },
  mounted() {
    this.getCoupons();
  },
  watch: {
    date: {
      handler: function (val) {
        // this.endDate = moment(val).add(1, "day").format("YYYY-MM-DD");
      },
    },
  },
  methods: {
    openModalExport() {
      this.$refs.exportModal.openModalExport();
    },
    openModal() {
      this.$refs["modalCreatePromoCode"].show();
    },
    createCoupon() {
      this.$refs["modalCreatePromoCode"].hide();
      var query = `organizations/${this.userAccess.organization.id}/promo-codes?count=${this.form.count}`;
      ApiService.post(query, {
        amount_discount: this.form.amount_discount,
        remarks: this.form.remarks,
      }).then((response) => {
        this.getCoupons();
      });
    },
    getCoupons() {
      var query = `organizations/${this.userAccess.organization.id}/promo-codes?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}`;
      ApiService.get(query).then((response) => {
        this.pagination.totalRows = response.data.meta.total;

        var count;
        if (this.pagination.currentPage == 1) {
          count = this.pagination.currentPage;
        } else {
          count = this.pagination.currentPage * this.pagination.perPage - this.pagination.perPage + 1;
        }

        this.promoCodes = response.data.data;
      });
    },
    pageClicks(page) {
      this.pagination.currentPage = page;
      this.getCoupons();
    },
    changePerPage() {
      this.pagination.currentPage = 1;
      this.getCoupons();
    },
    // async exportToExcel() {
    //   var route = await this.signRoute("organizations.promo-codes.export", {
    //     organization: this.userAccess.organization_id,
    //     start_date: "2023-07-01",
    //     end_date: "2023-07-31",
    //     is_redeemed: "Yes", // Yes, No, null
    //   });
    //   window.open(route, "_blank");
    // },

  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMMM YYYY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
    getConvertDate: function (tempDate) {
      if (!tempDate) return "-";
      var date = new Date(tempDate);
      var dateConvert = (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "/" + (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "/" + date.getFullYear();
      return dateConvert;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userRole", "userAccess"]),
    orgCode() {
      return this.userAccess?.organization?.code;
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white" style="text-transform: capitalize">{{ $t("promo-code") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container card public-profile border-0 rounded shadow overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-right">
              <button @click="openModalExport" class="btn btn-primary tt-cap mr-2">{{ $t("export") }}</button>
              <button @click="openModal" class="btn btn-primary tt-cap">{{ $t("generate-code") }}</button>
            </div>
          </div>

          <div class="table-responsive bg-white shadow rounded mt-3">
            <table class="table mb-0 table-center table-bordered" style="font-size: 12px">
              <thead class="bg-light">
                <tr>
                  <th>#</th>
                  <th width="120" class="tt-cap">{{ $t("code") }}</th>
                  <th>{{ $t("organization") }}</th>
                  <th class="tt-cap">{{ $t("discounted-amount") }}</th>
                  <th class="tt-cap">{{ $t("remarks") }}</th>
                  <th class="tt-cap">{{ $t("generated-at") }}</th>
                  <th class="tt-cap">{{ $t("expired-at") }}</th>
                  <th class="tt-cap">{{ $t("generated-by") }}</th>
                  <th class="tt-cap">{{ $t("redeemed-at") }}</th>
                  <th class="tt-cap">{{ $t("redeemed-by") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(promoCode, index) in promoCodes" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ promoCode.code }}</td>
                  <td>{{ promoCode.organization }}</td>
                  <td>RM {{ promoCode.amount_discount }}</td>
                  <td>{{ promoCode.remarks }}</td>
                  <td>{{ promoCode.generated_at | toHumanDateTime }}</td>
                  <td>{{ promoCode.expired_at | toHumanDateTime }}</td>
                  <td>{{ promoCode.generated_by }}</td>
                  <td>{{ promoCode.redeemed_at | toHumanDateTime }}</td>
                  <td>{{ promoCode.redeemed_by }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-9 col-md-7">
              <b-pagination v-model="pagination.currentPage" :total-rows="pagination.totalRows" :per-page="pagination.perPage" @change="pageClicks" aria-controls="my-table"></b-pagination>
            </div>
            <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="form custom-form">
                <div class="form-group mb-0">
                  <select v-model="pagination.perPage" class="form-control custom-select" id="Sortbylist-job" @change="changePerPage()">
                    <option value="5">5 {{ $t("per-page") }}</option>
                    <option value="10">10 {{ $t("per-page") }}</option>
                    <option value="25">25 {{ $t("per-page") }}</option>
                    <option value="50">50 {{ $t("per-page") }}</option>
                    <option value="100">100 {{ $t("per-page") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal ref="modalCreatePromoCode" class="modal fade" v-bind:title="$t('create-coupon')" v-bind:ok-title="$t('save')" v-bind:cancel-title="$t('cancel')" @ok="createCoupon">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="tt-cap">
                {{ $t("remarks") }}
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" v-model="form.remarks" />
            </div>
            <div class="col-md-12 form-group">
              <label class="tt-cap">
                {{ $t("discounted-amount") }} (RM)
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" v-model="form.amount_discount" />
            </div>
            <div class="col-md-12 form-group">
              <label class="tt-cap">
                {{ $t("total-code-to-generate") }}
                <span class="text-danger">*</span>
              </label>
              <input type="number" class="form-control" v-model="form.count" />
            </div>
          </div>
        </div>
      </b-modal>
    </section>
    <Footer />
    <ExportModal ref="exportModal" />
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
