<script>
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      type: "Bank",
      moveableProperty: {},
      property: {},
      preferenceLabel: null,
      hibah_type_id: null,
    };
  },
  computed: {
    ...mapGetters(["preference", "hibah"]),

    hibahId() {
      return this.$route.query.hibah_id ?? null;
    },
    amendmentId() {
      return this.$route.query.amendment_id ?? null;
    },
    propertyId() {
      return this.$route.query.property_id;
    },
    setMoveableArray() {
      console.log("hibah_type_id:", this.hibah);
      return [
        { name: "Bank", value: "Bank" },
        { name: "Kenderaan", value: "Kenderaan" },
        { name: "Simpanan", value: "Simpanan" },
        { name: "Insurance", value: "Insurance" },
        { name: "Saham", value: "Saham" },
      ];
    },
  },
  mounted() {
    this.getProperty();
  },
  methods: {
    getProperty() {
      ApiService.get(`moveable-property/${this.propertyId}`).then(
        (response) => {
          console.log(
            "property:",
            response.data.moveable_property.property_type
          );
          // this.property = response.data.moveable_property ?? {};
          this.moveableProperty = response.data.moveable_property;
        }
      );
    },
    updateDetails() {
      this.moveableProperty._method = "PATCH";
      ApiService.post(
        `moveable-property/${this.propertyId}`,
        this.moveableProperty
      ).then((response) => {
        this.$notify({
          group: "foo",
          title: this.$t("assets-successfully-update"),
          duration: 5000,
          type: "success",
        });
      });
    },
  },
  watch: {
    preference: {
      handler: function (val) {
        if (val) {
          console.log("vla", val);
          // this.moveableProperty.property_type = val.hibah_fixed_property_type;
          // this.moveableProperty.other_type = val.hibah_fixed_saham_type;
          // this.moveableProperty.property_type = val.hibah_fixed_saham_type;
          this.loader = true;
          this.preferenceLabel = val.corporate_saham_account_type;
        }
      },
    },

    trust: {
      handler: function (val) {},
    },
  },
};
</script>


<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 form-group" v-if="hibah">
        <label for="Jenis Harta">{{ $t("property-type2") }}</label>
        <p class="form-control-static">{{ moveableProperty.property_type }}</p>
      </div>
    </div>
    <div class="row" v-if="moveableProperty.property_type == 'Bank'">
      <div class="col-md-6">
        <div class="form-group">
          <label for="bank_type">{{ $t("bank-type") }}</label>
          <input
            type="text"
            id="bank_type"
            v-model="moveableProperty.bank_type"
            class="form-control"
            placeholder="Contoh: CIMB, MBB, BIMB"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="bank_branch">{{ $t("bank-branches") }}</label>
          <input
            type="text"
            id="bank_branch"
            v-model="moveableProperty.bank_branch"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="bank_account_no">{{ $t("accmembership-no") }}</label>
          <input
            type="text"
            id="bank_account_no"
            v-model="moveableProperty.bank_account_no"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="property_value">{{ $t("property-value") }}</label>
          <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">RM</span>
          </div>
          <input
            type="text"
            id="property_value"
            v-model="moveableProperty.property_value"
            class="form-control"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="moveableProperty.property_type == 'Kenderaan'">
      <div class="col-md-6 form-group">
        <label for="">
          {{ $t("vehicle-type") }}
          <img
            content="Contoh : motosikal, kereta, van, lori, basikal dan lain-lain"
            v-tippy
            height="15"
            class="mb-1"
            src="/images/icon/info-button.png"
          />
        </label>
        <input
          type="text"
          class="form-control"
          v-model="moveableProperty.transport_type"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">
          {{ $t("made-in") }}
          <img
            content="Contoh : Proton, Produa, Honda, Toyota, Yamaha dan lain-lain"
            v-tippy
            height="15"
            class="mb-1"
            src="/images/icon/info-button.png"
          />
        </label>
        <input
          type="text"
          class="form-control"
          v-model="moveableProperty.transport_manufacturer"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">
          {{ $t("made-in") }}
          <img
            content="Contoh : Viva 850, CR-V 2.0L I-VTEC (A), KRISS AN110F dan lain-lain"
            v-tippy
            height="15"
            class="mb-1"
            src="/images/icon/info-button.png"
          />
        </label>
        <input
          type="text"
          class="form-control"
          v-model="moveableProperty.transport_model"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">
          {{ $t("org.reg-no") }}
          <img
            content="Contoh : BNF 2705"
            v-tippy
            height="15"
            class="mb-1"
            src="/images/icon/info-button.png"
          />
        </label>
        <input
          type="text"
          class="form-control"
          v-model="moveableProperty.transport_registration_number"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">
          {{ $t("property-value") }}
        </label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">RM</span>
          </div>
          <input
            type="text"
            id="property_value"
            v-model="moveableProperty.property_value"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="moveableProperty.property_type == 'Saham'">
      <div class="col-md-12">
        <div class="form-group" v-if="['1', '2'].includes(hibah.hibah_type_id)">
          <label> {{ $t("stock-name") }} </label>
          <input
            type="text"
            name="hibah_fixed_saham_type"
            class="form-control"
            v-model="preference.hibah_fixed_saham_type"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label> {{ $t("org-name") }}</label>
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Nama Organisasi*"
            v-model="moveableProperty.institution_name"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label
            v-if="
              hibah &&
              ['1', '2'].includes(hibah.hibah_type_id) &&
              preferenceLabel
            "
          >
            {{ preferenceLabel }}
          </label>
          <label v-if="!['1', '2'].includes(hibah.hibah_type_id)">
            {{ $t("accmembership-no") }}
          </label>
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="No Akaun / No Ahli*"
            v-model="moveableProperty.account_number"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>{{ $t("property-value") }}</label>
          <input
            type="text"
            name=""
            class="form-control"
            placeholder="Nilai Harta*"
            v-model="moveableProperty.property_value"
          />
        </div>
      </div>
    </div>
    <!-- <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="office_number">{{ $t("property-type2") }}</label>
            <select
              name=""
              id=""
              class="form-control"
              v-model="moveableProperty.other_type"
            >
              <option value=""></option>
              <option value="Saham">{{ $t("shares") }}</option>
              <option value="Insuran">Insuran</option>
              <option value="Simpanan">{{ $t("savings") }}</option>
              <option value="Syarikat">{{ $t("company") }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="office_number">Jenis Keahlian</label>
            <select
              name=""
              id=""
              class="form-control"
              v-model="moveableProperty.other_membership_type"
            >
              <option value=""></option>
              <option value="No. Akaun">No. Akaun</option>
              <option value="No. Ahli">No. Ahli</option>
              <option value="No. Polisi">No. Polisi</option>
              <option value="No. Syarikat">No. {{ $t("company") }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="value">Jumlah Unit</label>
            <input
              type="text"
              id="value"
              v-model="moveableProperty.value"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="agreement_number">No Perjanjian</label>
            <input
              type="text"
              id="agreement_number"
              v-model="moveableProperty.agreement_number"
              class="form-control"
            />
          </div>
        </div>
      </div> -->
    <div class="row">
      <div class="col-md-12 text-right">
        <div>
          <button
            type="button"
            @click="updateDetails"
            class="btn btn-primary"
            v-if="[0, 1, 2].includes(hibah.status) || amendmentId"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>