<script>
import ApiService from "@/services/api.service";
import { TheMask } from "vue-the-mask";
import { tippy } from "vue-tippy";
import { mapGetters } from "vuex";
import { TRUST } from "@/services/store/trust.module";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      signature: {
        public_path: null,
      },
      otpcomplete: false,
      amendment_id: null,
      acceptPdpa: false,
      payment_received_at: null,
      paymethod: null,
      checkPdpa: false,
      btnotptext: "MOHON OTP",
      btnSetujuSign: false,
      btnSendTOAwaris: false,
      // form: {},
      isKoperate: null,
      submit: false,
      acceptPay: false,
      btnNotification: false,
      otpSent: false,

      mykad: {
        front: null,
        frontUrl: null,
        back: null,
        backUrl: null,
      },
      form: {
        acknowledged_pdpa: 0,
        acknowledged_confirmation: 0,
        is_cash_payment: 0,
      },
      receipt: {
        image: null,
        imageUrl: null,
      },
      updateStatus: {
        _method: "PATCH",
        status: 1,
        amendmentable_type: "Hibah",
      },
      verificationSent: false,
    };
  },
  validations: {
    receipt: {
      imageUrl: {
        required,
      },
    },
  },
  mounted() {
    // if (this.amendmentId && this.amendment) {
    //   this.amendment_status = this.amendment[0].status;
    //   this.amendment_id = this.amendment[0].id;
    // }
    if (
      this.trust &&
      ![0, 1].includes(this.trust.status) &&
      !this.amendmentId
    ) {
      this.acceptPdpa = true;
    } else if (this.amendment && this.amendment.status != 0) {
      this.acceptPdpa = true;
    }
    this.getSignature();
  },

  methods: {
    // getamendment() {
    //   ApiService.get(`/amendment?hibah_id=${this.trustId}`)
    //     .then((response) => {
    //       // if(Array.isArray(response.data.amendments.data) && )
    //       var data_amendment = response.data.amendments.data;
    //       if (Array.isArray(data_amendment) && data_amendment.length > 0) {
    //         this.amendment_status = data_amendment[0].status;
    //         this.amendment_id = data_amendment[0].id;
    //         console.log("data_amendment", data_amendment);
    //       }

    //       // console.log(response.data.amendments.data);
    //     })
    //     .catch((error) => {});
    // },
    acceptPdpaFunc() {
      if (this.acceptPdpa == 1) {
        this.checkPdpa = true;
      } else {
        this.checkPdpa = false;
      }
    },
    filesChange(files, type) {
      const formData = new FormData();
      formData.append("document", files[0], "nama file");

      this.receipt.image = files[0];
      this.receipt.imageUrl = URL.createObjectURL(files[0]);
      // }
    },
    async receiptSubmit() {
      if (this.receipt.image) {
        const formData = new FormData();
        formData.append("type", "payment");
        formData.append("document", this.receipt.image, "nama file");
        return await ApiService.post(
          `/hibah/${this.trustId}/document`,
          formData
        )
          .then((response) => {
            return true;
            console.log(response);
          })
          .catch((error) => {
            return false;
            console.log(error);
          });
      } else {
        return true;
      }
    },
    backButton() {
      this.$router.push({
        name: "trust.part-listpindaan",
        query: { trust_id: this.trustId },
      });
    },
    async saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log("Signature data empty: ", isEmpty);
      if (!isEmpty) {
        return await ApiService.post(`/hibah/${this.trustId}/signature`, {
          description: "hibah",
          is_base64: true,
          document: data,
        })
          .then((response) => {
            return true;
          })
          .catch((error) => {
            this.submit = false;
            return false;
          });
      } else {
        this.$notify({
          group: "foo",
          title: this.$t("pls-sign-before-submit"),
          type: "error",
        });
        this.submit = false;
        return false;
      }
    },
    // async paymentGateWay() {
    //   var url = window.location.href;
    //   var pay = {
    //     user_id: this.trustDetails.user_id,
    //     title: "Register Habiah Payment",
    //     amount: this.trustDetails.price,
    //     type: "Wasiat",
    //     description: "pay hibah register",
    //     redirect_url: url,
    //     paymentable_id: this.wasiat.id,
    //     paymentable_type: "Hibah",
    //   };
    //   console.log(pay);
    //   var resultPay = await this.toyepPya();
    //   if (resultPay) {
    //     await this.emailClientCreated();
    //     window.location.replace(resultPay);
    //   }
    //   this.form.status = 1;
    //   await this.updateHibah();
    // },
    async toyepPya() {
      return await ApiService.post(`payment`, pay)
        .then((response) => {
          var link = response.data.payment.payment_link;
          // this.$router.push(link);
          return link;
          // this.$router.push({ link });
        })
        .catch((error) => {
          // this.acceptPay = false;
          return false;
          console.log(error);
          // this.submit = false;
        });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async sendVerification() {
      console.log(this.trust);
      await this.emailClientCreated();
      this.verificationSent = true;
    },
    async emailClientCreated() {
      return await ApiService.get(
        `user/${this.trust.user_id}/email-client-created?product=Hibah`
      )
        .then((response) => {
          this.$store.dispatch(TRUST, this.trustId);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    async finish() {
      if (this.userRole != "Client" && this.trust && this.trust.status == 1) {
        this.$swal.fire({
          icon: "error",
          html: this.$t("grant-access-process"),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.$t("close"),
        });
        return;
      }
      if (this.trust.status == 0) {
        //if not pindaan
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$swal.fire({
            icon: "error",
            html: this.$t("fill-in-form"),
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: this.$t("close"),
          });
          return;
        }
        let receiptSave = await this.receiptSubmit();
        if (receiptSave) {
          this.form.status = 1;
          let resultHibah = await this.updateHibah();
          console.log("resultHibah", resultHibah);
          if (resultHibah) {
            await this.emailClientCreated();
            this.$notify({
              group: "full-noti",
              title: "Amanah/Hibah telah berjaya dijana.",
              duration: 5000,
              type: "success",
            });

            this.$router.push({
              name: "dashboard",
              query: {
                statusHibah: "success",
              },
            });
          }
        }
      } else if (!this.trust.status == 0) {
        //if client yang open
        if (this.acceptPdpa != 1) {
          //check pdpa
          this.$swal.fire({
            icon: "error",
            html: "Sila tekan butang Deklarasi oleh Pemberi terlebih dahulu.",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: this.$t("close"),
          });
        } else {
          if (!this.otpcomplete) {
            this.$swal.fire({
              icon: "error",
              html: this.$t("pls-insert-otp"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
            });
            return;
          }

          //pdpa is true
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          console.log("Signature data empty: ", isEmpty);
          if (isEmpty) {
            //check signature
            this.$swal.fire({
              icon: "error",
              html: this.$t("pls-left-sign"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
            });
          } else {
            //if signature not empty
            var resultSign = await this.saveSignature();
            //client userrole and pindaan
            if (resultSign) {
              if (
                //penghibah update
                resultSign &&
                this.trust.status == 1 &&
                !this.amendmentId &&
                this.userRole == "Client"
              ) {
                this.form.status = 2;
                let resultHibah = await this.updateHibah();
                if (resultHibah) {
                  this.$router.push({
                    name: "dashboard",
                    query: {
                      statusHibah: "success",
                    },
                  });
                }
              }
            }
          }
        }
      }
    },
    async updateAmendment() {
      let obj = {
        _method: "PATCH",
        // amendmentable_type: "Hibah",
        // amendmentable_id: this.trustId,
        // notes: null,
        status: 1,
      };

      return await ApiService.post(`/amendment/${this.amendmentId}`, obj)
        .then((response) => {
          var data_obj = response.data.amendment;
          console.log(data_obj);
          this.amendment_array = data_obj;
          console.log("");
          this.amendment_id = data_obj.id;
          // this.$router.push({
          //   name: "trust.part-a",
          //   query: {
          //     trust_id: this.trustId,
          //     amendment_id: this.amendment_id,
          //   },
          // });

          // pindaan.part_a
          return true;
        })
        .catch((error) => {
          console.log("error");
          return false;
        });
    },
    // getPaymentMethod(val) {
    //   // console.log(val);
    //   ApiService.get(`/organization/${val.organization_id}`)
    //     .then((response) => {
    //       console.log("ORG=", response.data);
    //       let allow_cash_payment =
    //         response.data.organization.allow_cash_payment;
    //       let allow_payment_gateway =
    //         response.data.organization.allow_payment_gateway;
    //       if (allow_cash_payment == 1 && allow_payment_gateway == 1) {
    //         this.paymethod = "both";
    //       } else {
    //         this.paymethod = "cash";
    //       }
    //     })
    //     .catch((error) => {});
    // },
    async updateCashPayment() {
      this.form.status = 1;
      this.form.is_cash_payment = 1;
      await this.updateHibah();
      await this.emailClientCreated();

      // this.acceptPay = true;
      // ApiService.post(`hibah/${this.trustId}`, {
      //   _method: "PATCH",
      //   is_cash_payment: 1,
      // })
      //   .then((response) => {
      //     this.payment_received_at = response.data.wasiat.payment_received_at;
      //   })
      //   .catch((error) => {
      //     this.acceptPay = false;
      //     // this.submit = false;
      //   });
    },
    showReceipt() {
      if (this.trust && ![0, 1].includes(this.trust.status)) {
        window.open(this.receiptShow.imageUrl, "_blank");
      } else {
        window.open(this.receipt.imageUrl, "_blank");
      }
    },
    async getSignature() {
      return await ApiService.get(
        `/hibah/${this.trustId}/signature?stream=false`
      )
        .then((response) => {
          console.log("getSignature", response.data);
          this.signature = response.data.signature;
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    async requestOtp() {
      if (this.btnotptext == "MOHON OTP") {
        //kelaur swal
        ApiService.post(`/sms/code`, {
          phone_number: this.trust.user.mobile_number,
        })
          .then((response) => {
            console.log(response.data);
            if (this.amendmentId) {
              this.otpSent = true;
            }
            this.btnotptext = "SAHKAN OTP";
          })
          .catch((error) => {});
      } else if (!this.amendmentId && this.btnotptext == "SAHKAN OTP") {
        await this.verifyOtp();
        this.otpSent = false;
      }
    },
    async verifyOtp() {
      return await ApiService.post(`/sms/verify`, {
        phone_number: this.trust.user.mobile_number,
        code: this.otpcode,
      })
        .then((response) => {
          console.log(response.data);
          this.btnSetujuSign = true;
          this.closeOTP = true;
          this.otpcomplete = true;
          this.btnSendTOAwaris = true;

          // this.$swal.fire({
          //   icon: "success",
          //   html: "Anda telah berjaya mengesahkan kod OTP.",
          //   showCloseButton: true,
          //   showCancelButton: false,
          //   focusConfirm: false,
          //   confirmButtonText: this.$t("close"),
          // });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            html: this.$t("wrong-otp"),
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: this.$t("close"),
          });
        });
    },
    handleOnComplete(value) {
      this.otpcode = value;
      console.log("OTP completed: ", value);
    },
    handleOnChange(value) {
      this.otpcomplete = false;

      this.otpcode = value;
      console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    async updateHibah() {
      this.form._method = "PATCH";
      console.log("form", this.form);
      return await ApiService.post(`/hibah/${this.trustId}`, this.form)
        .then((response) => {
          console.log(response.data);
          this.payment_received_at = response.data.hibah.payment_received_at;
          return true;
        })
        .catch((error) => {
          this.acceptPay = false;
          return false;
        });
    },
    modalPG() {
      this.$refs["modalPG"].show();
    },
    // async getDocument() {
    //   ApiService.get(`/hibah/${this.trustId}/document`)
    //     .then((response) => {
    //       var filter = response.data.attachments.data.filter(
    //         (item) => item.type == "payment"
    //       );
    //       this.receipt.imageUrl = filter[0].public_path;
    //       console.log("getDocument", this.receipt.imageUrl);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    async updateAmendmentStatus() {
      if (!this.otpcode) {
        this.$swal.fire({
          icon: "error",
          html: this.$t("request-otp"),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.$t("close"),
        });
        return;
      }
      await this.verifyOtp();
      if (this.btnSendTOAwaris) {
        ApiService.post(`/amendment/${this.amendmentId}`, this.updateStatus)
          .then((response) => {
            this.$swal.fire({
              icon: "success",
              html:
                this.$t("amendment-submit-confirmation"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
              onClose: () => {
                this.$router.push({
                  name: "trust.part-listpindaan",
                  query: {
                    trust_id: this.trustId,
                    amendment_id: this.amendmentId,
                  },
                });
              },
            });

            console.log(response.data);
          })
          .catch((error) => {});
      } else {
        this.$swal.fire({
          icon: "error",
          html: this.$t("request-otp-first"),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.$t("close"),
        });
      }
    },
    openotp() {
      // this.acceptPdpa = true;
      this.checkPdpa = true;
      this.btnSetujuSign = false;
    },
  },
  computed: {
    ...mapGetters(["userRole", "userAccess", "preference"]),
    trustId() {
      return this.$route.query.trust_id;
    },
    userAccessCheck() {
      return this.userAccess;
    },
    trustDetails() {
      return this.trust;
    },

    amendmentId() {
      return this.$route.query.amendment_id;
    },
    displayAgreement() {
      if (this.userRole == "Client") {
        return true;
      } else if (this.trust && this.trust.status == 1 && !this.amendmentId) {
        return true;
      } else if (
        this.trust &&
        this.trust.status == 2 &&
        this.userRole == "Client" &&
        !this.amendmentId
      ) {
        return true;
      } else if (
        this.trust &&
        [3, 4, 5].includes(this.trust.status) &&
        !this.amendmentId
      ) {
        return true;
      } else if (this.amendmentId && this.amendment) {
        return true;
      } else {
        return false;
      }
    },
    checkBoxdisable() {
      if (this.amendment && this.amendment.status != 0) {
        return true;
      } else if (
        this.trust &&
        ![0, 1].includes(this.trust.status) &&
        !this.amendment
      ) {
        return true;
      } else {
        return false;
      }
    },
    displaySign() {
      if (
        this.trust &&
        this.userRole != "Client" &&
        this.amendment &&
        this.amendment.status == 1
      ) {
        return true;
      } else if (
        this.trust &&
        this.userRole == "Client" &&
        this.amendment &&
        [3, 4, 5].includes(this.amendment.status)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    amendment: {
      handler: function (val) {
        if (val && this.amendmentId) {
          console.log("amendment", val);
          this.amendment_status = val.status;
          this.amendment_id = val.id;
        }
      },
    },
  },
  components: { TheMask },
  props: {
    trust: {
      type: Object,
    },
    amendment: {
      type: Object,
    },
    receiptShow: {
      type: Object,
    },
  },
};
</script>

<template>
  <div class="col-lg-12 col-12 mb-4 pb-2">
    <div class="row">
      <div class="col-lg-12 col-12 mb-4 pb-2">
        <div
          class="card bg-light job-box rounded shadow border-0 overflow-hidden"
        >
          <div
            class="card-body text-center"
            v-if="trust && [0, 2].includes(trust.status)"
          >
            <h5 class="text-center mb-5">{{ $t("verification") }}</h5>
            <div v-if="trust && trust.user && trust.status == 1">
              {{ $t("confirmation-sent-to-donor") }}
            </div>

            <div
              class="row d-flex justify-content-center mt-3"
              v-if="trust && trust.status == 2"
            >
              <div
                class="form-group"
                style="width: 40%"
                v-if="userRole != 'Client'"
              >
                <label for="upload-photo-2"> {{ $t("proof-of-payment") }} </label>
                <div class="card rounded shadow bg-dark">
                  <div
                    class="d-flex align-items-center justify-content-center bd-highlight"
                    style="height: 150px"
                  >
                    <label
                      v-if="receiptShow.imageUrl"
                      :style="
                        'background-image: url(' + receiptShow.imageUrl + ');'
                      "
                      style="
                        border-radius: 5px;
                        margin-bottom: 0;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                      "
                      for="upload-photo-2"
                    ></label>
                  </div>
                </div>
                <b-button class="mt-2 btn-sm btn-primary" @click="showReceipt">
                  {{ $t("view") }}
                </b-button>
              </div>
            </div>

            <div v-if="trust && trust.status == 0">
              <div class="card card-custom gutter-b">
                <div class="card-header">
                  <div class="card-title m-0">
                    <h5 class="card-label m-0">{{ $t("payment1") }}</h5>
                  </div>
                </div>
                <div class="card-body" v-if="userRole != 'Client'">
                  <p>
                    {{ $t("pls-upload-proof-payment") }}
                    <strong>RM {{ trust.price }}</strong>
                  </p>
                  <div class="row d-flex justify-content-center">
                    <div
                      class="form-group"
                      :class="{ 'has-error': $v.receipt.imageUrl.$error }"
                    >
                      <label for="upload-photo-2">
                        {{ $t("upload-proof-payment") }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="card rounded shadow bg-dark">
                        <div
                          class="d-flex align-items-center justify-content-center bd-highlight"
                          style="height: 150px"
                        >
                          <label
                            v-if="receipt.imageUrl"
                            :style="
                              'background-image: url(' + receipt.imageUrl + ');'
                            "
                            style="
                              border-radius: 5px;
                              margin-bottom: 0;
                              background-position: center;
                              background-repeat: no-repeat;
                              background-size: cover;
                              height: 100%;
                              width: 100%;
                            "
                            for="upload-photo-2"
                          ></label>

                          <label
                            class="upload-photo-dark text-center"
                            for="upload-photo-2"
                            v-if="!receipt.imageUrl"
                          >
                            <span>{{ $t("select-copy") }}</span>
                          </label>
                          <input
                            type="file"
                            name="photo"
                            id="upload-photo-2"
                            class="upload-photo-input"
                            accept="image/*"
                            @change="
                              filesChange($event.target.files, 'receipt')
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="form-error"
                        v-if="
                          $v.receipt.imageUrl.$error &&
                          !$v.receipt.imageUrl.required
                        "
                      >
                        {{ $t("proof-receipt") }} {{ $t("validation.cannot-blank") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" v-if="payment_received_at">
                  <p>
                    {{ $t("payment-has-been-received-amounting-to") }}
                    <strong>RM {{ trustDetails.price }}</strong>
                  </p>
                  <div class="row">
                    <div
                      class="col-md-6 d-flex justify-content-around"
                      v-if="userRole != 'Client'"
                    >
                      <button type="button" class="btn btn-primary">
                        {{ $t("send-notification") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary mb-4"
                @click="sendVerification"
                v-if="btnNotification"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span>
                    {{ $t("send-grantor-verification") }}
                    <small class="d-block" style="font-size: 8pt">
                      SMS: 0148220679</small
                    >
                    <small class="d-block" style="font-size: 8pt">
                      {{ $t("email") }}: penghibah1@yahoo.com</small
                    >
                  </span>
                  <img
                    src="/images/icon/direct.png"
                    alt=""
                    height="20"
                    class="pl-4"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-12 mb-4 pb-2" v-if="displayAgreement">
          <div
            class="card bg-light job-box rounded shadow border-0 overflow-hidden"
          >
            <div class="card-body">
              <h5 class="text-center mb-0">{{ $t("declaration-by-grantor") }}</h5>
              <div class="box-body mt-4" style="font-size: 10pt">
                <b>
                  A. {{ $t("agree-and-acknowledge") }}:
                </b>
                <br /><br />
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="2%" valign="top">a)</td>
                      <td width="98%" style="text-align: justify">
                        {{ $t( "acknowledgment") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">b)</td>
                      <td style="text-align: justify">
                        {{ $t("info-changes-to-awaris") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">c)</td>
                      <td style="text-align: justify">
                        {{ $t("asset-transfer-property") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">d)</td>
                      <td style="text-align: justify">
                        {{ $t("free-liabilities-charges-encumbrances") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">e)</td>
                      <td style="text-align: justify">
                        {{ $t("no-bankrupt") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">f)</td>
                      <td style="text-align: justify">
                        {{ $t("transfer-ownership") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">g)</td>
                      <td style="text-align: justify">
                        {{ $t("promise-inform-trustee") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">h)</td>
                      <td style="text-align: justify">
                        {{ $t("agree-authorize-details") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />

                <b style="text-align: justify">
                  B. {{ $t("declaration-act") }} </b
                ><br /><br />
                <p class="text-justify">
                  {{ $t("policy-awaris") }}
                </p>
                <br />
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="2%" valign="top">a)</td>
                      <td width="98%" style="text-align: justify">
                        {{ $t("confirm-establishment-trust") }}:
                      </td>
                    </tr>
                    <tr>
                      <td valign="top"></td>
                      <td style="text-align: justify">
                        i) {{ $t("criminal-activities") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top"></td>
                      <td style="text-align: justify">
                        ii) {{ $t("law-reg-amla") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">b)</td>
                      <td style="text-align: justify">
                        {{ $t("understand-agree-trust") }}:
                      </td>
                    </tr>
                    <tr>
                      <td valign="top"></td>
                      <td style="text-align: justify">
                        i) {{ $t("receive-distribute-criminal-act") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top"></td>
                      <td style="text-align: justify">
                        ii) {{ $t("involve-amla") }}
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">c)</td>
                      <td style="text-align: justify">
                        {{ $t("understand-amla") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <b>C. {{ $t("pdpa") }}</b><br /><br />
                <p style="text-align: justify">
                  {{ $t("pdpa-para2") }}
                </p>
                <br />
                <div
                  class="custom-control custom-checkbox text-center"
                  v-if="!amendmentId || (amendment && amendment.status != 0)"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="acceptPdpa"
                    :disabled="checkBoxdisable"
                    value="1"
                    @change="acceptPdpaFunc"
                  />
                  <label class="custom-control-label" for="customCheck1">
                    <strong>{{ $t("understand-declaration") }}</strong>
                  </label>
                </div>
                <div v-if="checkPdpa && !btnSetujuSign && trust">
                  <div
                    class="d-flex justify-content-center m-4"
                    v-if="
                      (trust && [0, 1].includes(trust.status)) || amendmentId
                    "
                  >
                    <div style="display: flex; flex-direction: row">
                      <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-center"
                    v-if="
                      (trust && [0, 1].includes(trust.status)) || amendmentId
                    "
                  >
                    <b-btn
                      v-if="!otpSent"
                      class="btn-sm btn-warning m-2"
                      @click="requestOtp"
                      >{{ btnotptext }}</b-btn
                    >
                  </div>
                  <div class="row d-flex justify-content-center">
                    <span v-if="otpSent"
                      ><small
                        >{{ $t("otp-sent") }}</small
                      ></span
                    >
                  </div>
                </div>
                <div class="text-center" v-if="btnSetujuSign && !amendmentId">
                  <div class="card" style="width: 100%; display: inline-block">
                    <div style="position: relative; height: 300px; width: 100%">
                      <VueSignaturePad
                        style="position: absolute; z-index: 1"
                        height="300px"
                        ref="signaturePad"
                        :options="{
                          onBegin: () => {
                            $refs.signaturePad.resizeCanvas();
                          },
                        }"
                      />
                      <label
                        style="
                          z-index: 0;
                          position: absolute;
                          right: 0px;
                          left: 0px;
                          top: 45%;
                          color: #b6b6b4;
                        "
                      >
                        {{ $t("sign-here") }}
                      </label>
                      <a
                        class="btn btn-primary"
                        style="
                          position: absolute;
                          right: 5px;
                          top: 5px;
                          z-index: 2;
                        "
                        @click="undo"
                      >
                        <img src="/images/icon/rubber.svg" height="20" alt />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="amendment && trust">
                <img
                  :src="signature.public_path"
                  alt
                  srcset
                  v-if="checkBoxdisable && signature.public_path"
                  style="width: 130px"
                  class="mt-3"
                />
                <div v-if="checkBoxdisable">
                  [
                  <strong v-html="trust.user_detail.name"></strong>]
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row mt-4"
          v-if="!amendmentId && trust && [0, 1].includes(trust.status)"
        >
          <div class="col-lg-12 text-right">
            <div class="form-group">
              <div class="d-flex justify-content-between"></div>
              <button @click="finish" type="button" class="btn btn-primary">
                {{ $t("done") }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="amendmentId && !checkPdpa && amendment && amendment.status == 0"
          class="d-flex justify-content-around"
        >
          <b-button class="btn btn-primary m-1" @click="backButton">
            {{ $t("back") }}
          </b-button>
          <b-button class="btn btn-primary m-1" type="button" @click="openotp">
            {{ $t("send-application") }}
          </b-button>
        </div>
        <div
          v-if="amendmentId && otpSent"
          class="d-flex justify-content-center"
        >
          <b-button class="btn btn-primary m-1" @click="updateAmendmentStatus">
            {{ $t("submit") }}
          </b-button>
        </div>
      </div>
    </div>
    <!-- <b-modal ref="modalPG" title="Jumlah Pembayaran" hide-footer>
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <label class="label-agree-term" for="is_cash_payment">
            <strong>SAYA BERSETUJU DENGAN BAYARAN SEBANYAK</strong>
          </label>
        </div>
        <div class="d-flex justify-content-center">
          <label v-if="trustDetails">
            <strong>RM {{ trustDetails.price }}</strong>
          </label>
        </div>
      </div>

      <b-button class="btn btn-danger" block @click="paymentGateWay"
        >Setuju</b-button
      >
    </b-modal> -->
  </div>
</template>
<style >
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>