<script>
import ApiService from "@/services/api.service";
import { TheMask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {};
  },
  validations: {},
  mounted() {},
  methods: {},
  props: {},
  computed: {},
  components: {
    TheMask,
  },
  watch: {},
};
</script>
<template>
  <div class="col-lg-12 col-12 mb-4 pb-2">
    <div class="card bg-light job-box rounded shadow border-0 overflow-hidden">
      <div class="card-body">
        <h5 class="text-center">{{ $t("info-abt-the-dead") }}</h5>
        <div class="form-group" :class="{ 'has-error': $v.form.name.$error }">
          <label for="exampleInputEmail1">
            {{ $t("name") }} <span class="text-danger">*</span></label
          >

          <div class="input-group">
            <div class="input-group-prepend">
              <select
                class="form-control"
                style="
                  border-bottom-right-radius: 0;
                  border-top-right-radius: 0;
                "
                @change="changeDesignation"
                v-model="form.designation"
                :disabled="true"
              >
                <option
                  v-for="designation in designations"
                  :key="designation"
                  :value="designation"
                >
                  {{ designation }}
                </option>
              </select>
            </div>
            <!-- {{ form.name }} -->
            <input
              type="text"
              v-uppercase
              class="form-control"
              name
              id="full_name"
              required
              v-model.trim="$v.form.name.$model"
              :disabled="true"
            />
          </div>
          <div
            class="form-error"
            v-if="$v.form.name.$error && !$v.form.name.required"
          >
            {{ $t("name") }} {{ $t("validation.cannot-blank") }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div
              class="form-group position-relative"
              :class="{ 'has-error': $v.form.ic_number.$error }"
            >
              <label for="ic_number">
                {{ $t("mykad") }}
                <span class="text-danger">*</span>
              </label>
              <the-mask
                id="ic_number"
                :mask="['######-##-####']"
                class="form-control"
                readonly
                v-model.trim="$v.form.ic_number.$model"
              />
              <div
                class="form-error"
                v-if="$v.form.ic_number.$error && !$v.form.ic_number.required"
              >
                {{ $t("validation.mykad") }}
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group position-relative">
              <label for="other_ic_number">
                {{ $t("old-ic") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="other_ic_number"
                v-model="form.other_ic_number"
                required
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group position-relative"
              :class="{ 'has-error': $v.form.date_of_death.$error }"
            >
              <label for="date_of_death"
                >{{ $t("date-of-death") }}
                <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                v-model="form.date_of_death"
                id="dob"
                required
                readonly
              />
              <div
                class="form-error"
                v-if="
                  $v.form.date_of_death.$error &&
                  !$v.form.date_of_death.required
                "
              >
                {{ $t("date-of-death") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group position-relative"
              :class="{ 'has-error': $v.form.gender.$error }"
            >
              <label for="gender">
                {{ $t("gender") }}
                <span class="text-danger">*</span>
              </label>
              <select
                name
                id="gender"
                class="form-control"
                v-model.trim="$v.form.gender.$model"
                :disabled="true"
              >
                <option value="">-- {{ $t("choose.gender") }} --</option>
                <option
                  v-for="(gender, index) in genders"
                  :key="index"
                  :value="gender.split(':')[0]"
                >
                  {{ gender.split(":")[1] }}
                </option>
              </select>
              <div
                class="form-error"
                v-if="$v.form.gender.$error && !$v.form.gender.required"
              >
                {{ $t("gender") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-error': $v.form.religion.$error }"
            >
              <label for="religion"
                >{{ $t("religion") }}<span class="text-danger">*</span></label
              >
              <select
                name=""
                class="form-control"
                v-model.trim="$v.form.religion.$model"
                @change="changeReligion"
                :disabled="true"
              >
                <option value="">-- {{ $t("choose.religion") }} --</option>
                <option
                  v-for="religion in religions"
                  :key="religion"
                  :value="religion"
                >
                  {{ religion }}
                </option>
              </select>
              <div
                class="form-error"
                v-if="$v.form.religion.$error && !$v.form.religion.required"
              >
                {{ $t("religion") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-error': $v.form.race.$error }"
            >
              <label for="race"
                >{{ $t("race") }}<span class="text-danger">*</span></label
              >
              <select
                name
                id="race"
                v-model.trim="$v.form.race.$model"
                class="form-control"
                @change="changeRace"
                :disabled="true"
              >
                <option value>-- {{ $t("choose.race") }} --</option>
                <option v-for="race in races" :key="race" :value="race">
                  {{ race }}
                </option>
              </select>
              <div
                class="form-error"
                v-if="$v.form.race.$error && !$v.form.race.required"
              >
                {{ $t("race") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{ 'has-error': $v.form.marital_status.$error }"
            >
              <label for="marital_status">{{ $t("marital-status") }}</label>
              <span class="text-danger">*</span>
              <select
                name=""
                class="form-control"
                v-model.trim="$v.form.marital_status.$model"
                :disabled="true"
              >
                <option value="">
                  -- {{ $t("choose.marital-status") }} --
                </option>
                <option value="Bujang">{{ $t("single") }}</option>
                <option value="Berkahwin">{{ $t("married") }}</option>
                <option value="Janda/Duda">{{ $t("widow-widower") }}</option>
              </select>
              <div
                class="form-error"
                v-if="
                  $v.form.marital_status.$error &&
                  !$v.form.marital_status.required
                "
              >
                {{ $t("marital-status") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
        </div>
        <hr class="primary" />

        <div class="row">
          <div class="col-md-12">
            <div
              class="form-group"
              :class="{ 'has-error': $v.form.address.$error }"
            >
              <label for="address">{{ $t("last-address") }}</label>
              <span class="text-danger">*</span>
              <textarea
                v-uppercase
                class="form-control"
                id="address"
                rows="2"
                v-model.trim="$v.form.address.$model"
                readonly
              ></textarea>
              <div
                class="form-error"
                v-if="$v.form.address.$error && !$v.form.address.required"
              >
                {{ $t("last-address") }} {{ $t("validation.cannot-blank") }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div
              class="form-group"
              :class="{ 'has-error': $v.form.postcode.$error }"
            >
              <label for="postcode" class="d-block">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    >{{ $t("postcode")
                    }}<span class="text-danger">*</span></span
                  >

                  <span
                    @click="openpostcode"
                    style="
                      text-decoration: underline;
                      font-size: 11px;
                      color: #333;
                    "
                    >{{ $t("add-new") }}</span
                  >
                </div>
              </label>
              <input
                type="text"
                id="postcode"
                class="form-control"
                @blur="getPostcode(1)"
                v-model.lazy.trim="$v.form.postcode.$model"
                readonly
              />
              <div
                class="form-error"
                v-if="$v.form.postcode.$error && !$v.form.postcode.required"
              >
                {{ $t("validation.postcode") }}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="city">{{ $t("city") }}</label>
              <input
                type="text"
                v-uppercase
                id="city"
                class="form-control"
                readonly
                v-model="form.city"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="state">{{ $t("state") }}</label>
              <input
                type="text"
                v-uppercase
                id="state"
                class="form-control"
                readonly
                v-model="form.state"
              />
            </div>
          </div>
        </div>

        <hr class="primary" />

        <div class="row">
          <div class="form-group position-relative col-md-6">
            <label for="income_source">{{ $t("source-income") }}</label>
            <select
              name=""
              class="form-control"
              v-model="formHibah.income_source"
              @change="dropdownSourceIncome($event)"
              :disabled="true"
            >
              <option
                v-for="incomeVal in income"
                :key="incomeVal"
                :value="incomeVal"
              >
                {{ incomeVal }}
              </option>
            </select>
          </div>
          <div
            class="form-group position-relative col-md-6"
            v-if="otherSourceIncome"
          >
            <label for="income_source">{{ $t("othersource-income") }}</label>
            <input
              type="text"
              v-uppercase
              name="income_source"
              placeholder="Sila nyatakan"
              v-model="form.income_source"
              class="form-control"
              readonly
            />
          </div>
          <div class="form-group position-relative col-md-6">
            <label for="hibah_property_source">{{
              $t("trust.propsources")
            }}</label>
            <select
              name="hibah_property_source"
              class="form-control"
              v-model="formHibah.hibah_property_source"
              @change="changeincomeproperty($event)"
              :disabled="true"
            >
              <option
                v-for="incomeproperty in income_property"
                :key="incomeproperty"
                :value="incomeproperty"
              >
                {{ incomeproperty }}
              </option>
              <!-- <option value="Pendapatan Perniagaan">
                Pendapatan Perniagaan
              </option>
              <option value="Pendapatan Pekerjaan">Pendapatan Pekerjaan</option>
              <option value="Pendapatan Pelaburan">Pendapatan Pelaburan</option>
              <option value="Hadiah Warisan">Hadiah Warisan</option>
              <option value="Lain-lain">Lain-lain</option> -->
            </select>
          </div>
          <div class="col-md-6" v-if="otherHibahPropSource">
            <div class="form-group">
              <label for="hibah_property_source">{{ $t("other-hibah-prop-resources") }}</label>
              <input
                type="text"
                name="hibah_property_source"
                id="hibah_property_source"
                placeholder="Sila nyatakan"
                v-model="form.hibah_property_source"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <!-- <div
            class="form-group position-relative col-md-6"
            :class="{ 'has-error': $v.formHibah.pricing_id.$error }"
          >
            <label for="pricing_id">
              {{ $t("trust.hibah-price") }}<span class="text-danger">*</span>
            </label>
            <select
              name=""
              id="pricing_id"
              class="form-control"
              v-model.trim="$v.formHibah.pricing_id.$model"
              :disabled="true"
            >
              <option
                :value="pricing.id"
                v-for="(pricing, index) in pricings"
                :key="index"
              >
                RM {{ pricing.price }}
              </option>
            </select>
            <div
              class="form-error"
              v-if="
                $v.formHibah.pricing_id.$error &&
                !$v.formHibah.pricing_id.required
              "
            >
              Harga Hibah tidak boleh dibiarkan kosong.
            </div>
          </div> -->
          <div class="form-group col-md-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="check_revocable"
                v-model="formHibah.is_revocable"
                value="1"
                disabled
              />
              <label class="custom-control-label" for="check_revocable">
                {{ $t("trust.can-revoked") }}
              </label>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="">{{ $t("trust.hibah-purpose") }}</label>
            <select
              name=""
              class="form-control"
              v-model="formHibah.trust_purpose_type"
              :disabled="true"
            >
              <option value="Institusi">{{ $t("institution") }}</option>
              <option value="Persendirian">{{ $t("private2") }}</option>
            </select>
          </div>
          <div
            class="form-group col-md-12"
            v-if="formHibah.trust_purpose_type == 'Institusi'"
          >
            <textarea
              name=""
              cols="30"
              rows="10"
              class="form-control"
              v-model="formHibah.trust_purpose"
              readonly
            ></textarea>
          </div>
          <div
            class="form-group col-md-12"
            v-if="formHibah.trust_purpose_type == 'Persendirian'"
          >
            <select
              name=""
              class="form-control"
              v-model="formHibah.trust_purpose"
              @change="changeHibahPrivate($event)"
              :disabled="true"
            >
              <option
                :value="hibahPrivate"
                v-for="(hibahPrivate, index) in hibah_private"
                :key="index"
              >
                {{ hibahPrivate }}
              </option>
            </select>
          </div>
        </div>

        <hr class="primary" />
        <div class="row">
          <div class="form-group position-relative col-md-6">
            <label for="testament_info">{{ $t("will-info") }}</label>
            <div class="row">
              <div class="form-group position-relative col-md-6">
                <div class="radio-inline">
                  <div>
                    <label class="radio">
                      <input
                        type="radio"
                        name="radio1"
                        value="1"
                        v-model="form.testament_info"
                        disabled
                      />
                      {{ $t("yes") }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group position-relative col-md-6">
                <div class="radio-inline">
                  <div>
                    <label class="radio">
                      <input
                        type="radio"
                        name="radio1"
                        value="2"
                        v-model="form.testament_info"
                        disabled
                      />
                      {{ $t("no") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12 text-right">
        <div class="form-group">
          <div class="d-flex justify-content-between"></div>
          <button @click="submitForm" class="btn btn-primary">
            {{ $t("save-next") }}
            <span class="spinner-border spinner-border-sm" v-if="submit"></span>
          </button>
        </div>
      </div>
    </div>
    <b-modal
      ref="modalPostcode"
      class="modal fade"
      v-bind:title="$t('add-newpostcode')"
      v-bind:ok-title="$t('add')"
      v-bind:cancel-title="$t('cancel')"
    >
      <div class="modal-body">
        <div class="form-group">
          <label>
            {{ $t("postcode") }}
            <span class="text-danger">*</span>
          </label>
          <input type="number" class="form-control" />
        </div>
        <div class="form-group">
          <label>
            {{ $t("city") }}
            <span class="text-danger">*</span>
          </label>
          <input type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label>
            {{ $t("state") }}
            <span class="text-danger">*</span>
          </label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </b-modal>
  </div>
</template>
